@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&family=Tajawal:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Amiri:700&display=swap);
.aboutBG {
    background: url(/static/media/reachHealthAppBG.a4713830.svg);
}

@media (max-width: 768px) {

    .home-form-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

.news-carousel .post-cover {
  height: 22.5rem;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 1rem 0 rgba(0, 0, 0, 0.12);
}

.news-carousel h4 {
  margin: 0;
}

.news-carousel a.read-more:link,
.news-carousel a.read-more:visited {
  color: #aea8a8;
}

.news-carousel a.read-more:hover,
.news-carousel a.read-more:focus {
  text-decoration: none;
}

.news-carousel .post-category {
  position: absolute;
  background: #ff9790;
  border-radius: 32px;
  color: #ffffff;
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
}

/********************************/

/** @brainhubeu/react-carousel **/

/********************************/

/* LogoCarousel */

.logo-carousel .slider-arrow-right,
.logo-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

.logo-carousel {
  padding-bottom: 9rem;
}

.logo-carousel .slider-arrow-right,
.logo-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

/* NewsCarousel */

.news-carousel .slider-arrow-right,
.news-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

.news-carousel {
  padding-bottom: 8rem;
}

.news-carousel .BrainhubCarouselItem {
  cursor: pointer;
  align-items: flex-start;
}

.news-carousel .BrainhubCarouselItem--active h3 {
  display: block;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Newspage carousel */

.news-post .BrainhubCarouselItem > div {
  height: 400px;
  width: 100%;
  margin-bottom: 2rem;
  background-size: cover;
}

.news-post .BrainhubCarousel__customArrows {
  position: relative;
  color: #fff;
  z-index: 1;
}

.news-post .BrainhubCarousel__custom-arrowRight {
  left: -3rem;
}

.news-post .BrainhubCarousel__custom-arrowLeft {
  left: 3rem;
}

/* Quotes carousel */

.quotes-carousel .slider-arrow-right,
.quotes-carousel .slider-arrow-left {
  background: rgba(255, 153, 145, 0.1);
  border-radius: 39px;
}

.quotes-carousel {
  padding-bottom: 6rem;
  text-align: center;
  justify-content: center;
  text-justify: auto;
}

/* .quotes-carousel .slider-arrow-left {
    background: #fce5e5;
    left: 39%;
} */

/* carousel indicators

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  visibility: hidden;
  /* background-color: var(--primary);
  opacity: 0.7;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.5rem;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);


}
.team-section .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    background-color: #fff;
}

.BrainhubCarousel__dots
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
} */

.nayla-logo{
    position: relative;
    max-width: 150px;
}

.nayla-logo-img {
    width: 100%;
    object-fit: contain;
}

/* Homepage */

div.home-cover {
  background-image: linear-gradient(#fbfbfb, #fff);
}

div.home-cover > div {
  text-align: center;
}

div.home-cover img {
  height: 250px;
  display: inline-block;
  /* padding-left: 60px;
    padding-top: 25px; */
}

.home-cover .btn {
  height: 56px;
  width: 167px;
  /* margin-left: 1rem; */
}

.home-cover form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-cover #formBasicEmail {
  background: #ffffff;
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box;
  border-radius: 12px;
  width: 330px;
  height: 56px;
}

.how-section img {
  width: 120%;
}

.num-header {
  color: #c0e2f7;
  font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
  font-size: 100px;
  line-height: 120%;
}

div.categories + div.categories {
  border-left: 3px solid #c2e4f9;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-right: none;
}

.row:lang(ar) > div.categories + div.categories {
  border-right: 3px solid #c2e4f9;
  border-left: none;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

@media (min-width: 768px) {
  div.home-cover img {
    height: 445px;
  }
  div.home-cover > div {
    text-align: start;
  }
}

/* Large */

@media (min-width: 992px) {
  div.home-cover {
    margin-bottom: 5rem;
  }

  div.home-cover img {
    height: 560px;
  }
  .how-section img {
    width: 100%;
  }
  .num-header {
    font-size: 120px;
  }
}

/* Extra Large */

@media (max-width: 576px) {

    .home-cover #formBasicEmail {
        width: 100%;
        height: 100%;
    }
}


.post-header.topic-header {
    height: 350px;
    position: relative;
}

.topic-post {
    margin-top: -140px;
}

.depth-shadow.topic-depth-shadow {
    margin-bottom: 10rem;
    padding-bottom: 8rem;
}

.topic-link-item:hover {
    text-decoration: none;
}

.topic-image {
    width: 10rem;
}

.subtopic-tab-menu {
    background-color: red;
}

.subtopic-body-style {
    margin-top: -80px;
}

.subtopic-header-style {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.06);
}

.icon-design {
    color: #ADADAD;
}

.topic-center {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.topic-category-link {
    color: #000000;
    font-weight: 200;
    font-size: 1.2rem;
}

.topic-badge {
    background: #ff9790;
    border-radius: 32px;
    color: #ffffff;
    padding: .3rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
}

.subtopic-header {
    /*height: 175px;*/
    background-color: #f8f5f5;
}

.subtopic-body {
    background-color: #fff;
    border-radius: 16px;
}

.subtopic-body .titled-text {
    text-align: center;
}

.row.subtopic {
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
}

.subtopic-border {
    border-bottom: 1px solid #E6E6E6;
}

.topic-links {
    background-color: #FFF5F5;
    border-radius: 16px;
}

.topic-link {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    padding: 10px 0;
    font-weight: 600;
    color: #FF9D96;
}

.topic-link:hover {
    cursor: pointer;
}

.active-topic-link {
    background-color: #ff9385;
    color: white;
    text-align: center;
    border-radius: 10px;
}

.subtopic-links {
    color: #000000;
    font-size: 20px;
}

.subtopic-links:hover {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

/* TopicService */

.health-providers {
    border-radius: 16px;
}

.hospitals {
    position: relative;
    padding-right: 6px;
}

.hospitals::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.hospitals::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.hospitals::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.hospitals::-webkit-scrollbar-thumb:hover {
    background: rgb(160, 160, 160);
}

.hospital {
    background-color: white;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.not-found {
    display: flex;
    justify-content: center;
}

.hospital-images {
    position: relative;
    width: 100%;
    max-width: 10rem;
}

.hospital-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.hospital-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000000;
}

.button-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.see-all {
    color: #FF9D96;
    font-size: 16px;
    padding: 5px;
    background-color: #FDE5E3;
    border: 1px solid #FDE5E3;
    border-radius: 8px;
}

.see-all:hover {
    background-color: #FF9D96;
    color: white;
    border: 1px solid #FF9D96;
    text-decoration: none;
}

.phone-button {
    background-color: transparent;
    border: 2px solid #F4F4F4;
    border-radius: 8px;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.phone-icon {
    margin-right: 10px;
}

.sub-info {
    color: #C4C4C4;
    font-size: 15px;
}

.map-google {
    width: 100%
}

.sticky-map {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 30px;
}

.map-icon {
    position: relative;
}

.google-map-icon-wrapper {
    position: relative;
    width: 1.5rem;
    margin-top: -4.5rem;
}

.google-map-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* X-Small */

@media (max-width: 576px) {
    .subtitles-xs {
        font-family: 'Tiempos Text Medium', 'Amiri', serif;
        color: #163f70;
    }

    .subtitle-border {
        border-bottom: 1px solid #163f70;
    }

    .topic-link {
        line-height: 20px;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .button-wrapper {
        display: block;
    }

    .phone-button {
        margin-bottom: .6rem;
    }
}

/* Medium */

@media (max-width: 768px) {
    .subtopic-body .titled-text {
        text-align: center;
    }

    .hospital {
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .hospital-image {
        margin-top: 1rem;
    }

    .hospital-informations {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .google-map-icon-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }


    .topic-category-link {
        font-size: 1rem;
    }


}

/* Large */

@media (min-width: 992px) {
    .subtopic-header {
        /*height: 414px;*/
    }

    .subtopic-body {}

    .links-border {
        border-right: none;
    }
}

/* Extra Large */

@media (min-width: 1200px) {}
.accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.accordion-list__item {
    border-bottom: 1px solid #E6E6E6 !important;
    color: #000000;
    font-size: 20px;
}

.accordion-item__paragraph {
    color: #ADADAD;
    font-size: 16px;
}

.accordion-item--opened .accordion-item__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
}

.accordion-item--opened .accordion-item__content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
}

.accordion-item__line {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
    cursor: pointer;
}

.accordion-item__icon {

}

.accordion-item__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
}

.accordion-item__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
}

.wrapper {
    width: 100%;
}

.search__container {
    position: relative;
    z-index: 99;
    margin-top: -20px;
}

.form-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.search-button {
    position: absolute;
    background: none;
    border: none;
    font-family: monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.259259px;
    color: #163f70;
}

.search-button:hover {
    color: #ff9d96;
}

.search-box {
    width: 100%;
    padding: 0.5rem;
    padding-left: 40px;
    background-color: white;
    background-image: url(/static/media/new-search.b2c950d9.svg);
    background-repeat: no-repeat;
    background-position: 10px center;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    line-height: 30px;
}

/*.contact-header {
  height: 394px;
}

.contact-body {
  margin: -340px;
}

.contact-body .titled-text {
  text-align: center;
}*/

.form-group {
  position: relative;
}

.form-group > label {
  position: absolute;
  top: 0.7rem;
  left: 0.8rem;
  padding: 0 3px;
  background: none;
  color: #939393;
  transition: all 0.1s ease-in-out;
}

.form-group > label.active {
  position: absolute;
  top: -1rem;
  left: 0.8rem;
  padding: 0 3px;
  background: white;
  color: #939393;
  transition: all 0.1s ease-in-out;
}

.form-group > label.filled {
  position: absolute;
  top: -1rem;
  left: 0.8rem;
  padding: 0 3px;
  background: white;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label {
  position: absolute;
  top: 0.7rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: none;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label.active {
  position: absolute;
  top: -1rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: white;
  color: var(--primary);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label.filled {
  position: absolute;
  top: -1rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: white;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) #input-email,
form:lang(ar) #input-phone {
  direction: ltr;
  text-align: right;
}

#contact-form textarea {
  resize: none;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

/*@media (min-width: 768px) {
  .contact-header {
    height: 284px;
  }
  .contact-body {
    margin: -220px;
  }
  .contact-body .titled-text {
    text-align: start;
  }
}*/

/* Large */

/*@media (min-width: 992px) {
  .contact-header {
    height: 414px;
  }
  .contact-body {
    margin: -380px;
  }
}
*/
/* Extra Large */

@media (min-width: 1200px) {
}
.bg-gray-1 {
  background: #f2f2f2 !important;
}

.post-header {
  height: 250px;
  background: #ffe7e5;
}

.news-post {
  margin-top: -140px;
}

.author {
  opacity: 0.8;
  text-align: center;
}

.commentor {
  opacity: 0.8;
}

.comment-content:after {
  position: absolute;
  content: '';
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 3px rgba(143, 136, 136, 0.2);
  z-index: -1;
}
.newsParegraph {
  color: #000000;
}
.auttor {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  mix-blend-mode: normal;
}
.place {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
}
.social-text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.socialLink {
  display: inline-block;
  text-decoration: none;
  color: #000;
  margin: 0.5em;
  border-radius: 8px;
  transition: 50ms ease-out;
  padding: 0.5em 0.75em;
  line-height: 1.2em;
  background-color: #fff;
}
.socialLink:hover {
  text-decoration: none;
}
.socialIcon {
  fill: #fff;
  stroke-width: 0;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  vertical-align: bottom;
}
.scoialName {
  padding-left: 0.4em;
  line-height: 1.2em;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 56px;
  align-items: center;
  text-align: center;
  color: #000000;
}
.newsParegraph img {
  width: 100%;
}
.new-btn {
  width: 250px !important;
  border-radius: 12px !important;
}
.wp-image-35 {
  width: 100%;
}

.youtube-player {
  width: 100%;
}
.tiled-gallery__gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tiled-gallery__row {
  display: flex;
  justify-content: space-around;
}
.tiled-gallery__col {
  padding: 0 0.5rem;
}

.card-header {
  background-color: white !important;
}
.card-header {
  border-bottom: 0 !important;
}
.card {
  border: 0 !important;
  border-bottom: 1px solid #bddff4 !important;
}

/* search input form */

#search {
  border-radius: 50px !important;
  border: 3px solid #ff9d96 !important;
  text-align: right;
}
a.categories + a.categories {
  border-left: 3px solid #bddff4 !important;
}
.links {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Graphik Medium';
  src: url(/static/media/Graphik-Medium.4a127c95.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos Headline Bold Test';
  src: url(/static/media/TiemposHeadlineTest-Bold.b10904fe.otf) format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Tiempos Text Medium';
  src: url(/static/media/TiemposText-Medium.d489a560.otf) format('opentype');
  font-style: normal;
  font-weight: normal;
}

:root {
    --direction: ltr;
    --align: left;
    --primary: #163f70;
    --secondary: #ff9385;
}

body {
    direction: ltr;
    direction: var(--direction);
    margin: 0;
    font-family: 'Graphik', 'Almarai', sans-serif;
    font-size: 14px;
    line-height: 150%;
    color: #163f70;
    color: var(--primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    text-align: left;
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 35px;
}

h2 {
    text-align: left;
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 20px;
}

h3 {
    text-align: left;
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 20px;
}

h4 {
    text-align: left;
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 16px;
    margin: 8px 0;
}

.turkishFont h1,
.turkishFont h2,
.turkishFont h3,
.turkishFont h4 {
    font-family: 'PT Serif Bold', 'Amiri', serif;
}

div {
    text-align: left;
    text-align: var(--align);
}

p {
    text-align: left;
    text-align: var(--align);
}

.titled-text,
.titled-text > * {
    text-align: inherit;
}

.titled-text p {
    color: #000;
}

#header-title {
    color: #285287;
    line-height: 1.6;
}


.titled-text .main-description {
    font-size: 24px;
    line-height: 36px
}

.titled-text .standart-description {
    line-height: 32px;
}

.titled-text .secondary-description {
    font-size: 20px;
    line-height: 30px;
}

.highlight-pink {
    display: inline-block;
    background-image: url(/static/media/highlight-pink.21dbcd80.svg);
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0 5px;
}

.bg-gray {
    background-color: #f7f7f7;
}

.bg-light-blue {
    background: #e7f5fd;
}

.secondary-color {
    color: #ff9385;
    color: var(--secondary);
}

.secondary-bg {
    background-color: #fcedee;
}

.depth-shadow {
    box-shadow: 3px 3px 3px rgba(143, 136, 136, 0.2);
    background: #fff;
}

.btn {
    color: #fff;
    background-color: #163f70;
    background-color: var(--primary);
    border: none;
    border-radius: 12px;
    height: 46px;
    width: 15rem;
    font-size: 14px;
}

.btn:hover,
.btn:focus,
.btn:active {
    color: #ff9385;
    color: var(--secondary);
    background-color: #163f70;
    background-color: var(--primary);
}

.form-control {
    border: 1px solid #e6e6e6 !important;
    box-sizing: border-box;
    border-radius: 12px;
}

.form-control {
    padding: 1.375rem 0.75rem;
}

.categories:hover {
    background: #ffffff;
    box-shadow: 0px 24px 100px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    text-decoration: none !important;
}

.category-link:hover{
    text-decoration: none;
}

.terms-text {
    color: black;
}

.p1 {
    color: #285287;
    text-align: center;
    font-size: 36px !important;
    font-weight: 600;
}

.privacy-text {
    color: black;
}

/* About page */

.md-team-carousel .BrainhubCarouselItem {
    display: block;
}

.md-team-carousel {
    padding-bottom: 5rem;
}

.slider-arrow-right,
.slider-arrow-left {
    border-radius: 39px;
    padding: 1.3rem;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    /* left: 0; */
    z-index: 99;
    margin: 3rem;
}

.slider-arrow-left {
    left: 49%;
}

.slider-arrow-right {
    right: 49%;
    transform: rotate(180deg);
}

.md-team-carousel .slider-arrow-right,
.md-team-carousel .slider-arrow-left {
    background: #2e537e;
}

.team-section {
    color: #fff;
}

.team-card {
    background-color: #163f70;
    background-color: var(--primary);
    min-height: 30rem;
}

.team-member {
    min-height: 6rem;
}

.team-member > p {
    color: #fff;
    opacity: 0.8;
}

.country-button {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    width: 130px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: -0.416667px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 133%;
}

.country-button-active {
    background: #fff;
    color: #174070;
}

/* AppPage */

.screen-section {
    min-height: 34rem;
}

.screen img {
    width: 50%;
}

.interface img {
    width: 100%;
    height: 26rem;
    object-fit: cover;
    object-position: top;
}

.feature-star {
    padding-top: 0.6rem;
}

/* News Page */

.news-cover {
    background-color: #f7f7f7;
}

.news-cover img {
    display: inline-block;
}

.news-main {
    background: rgba(252, 237, 238, 0.36);
}

.news-card {
    border: none;
}

.news-card a {
    color: #ff9385;
    color: var(--secondary);
    text-decoration: none;
}

.newsexplore {
    position: absolute;
    z-index: -1;
}

.news-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000 !important;
}

.news-content:hover {
    text-decoration: none;
}

.text-blue {
    color: #285287;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
}

/* Small */

@media (min-width: 576px) {
}

.highlight {
    display: inline-block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAAZCAYAAAC1ken9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgB7dBBAQAQAAAx9FLSV1hS3G+LsPnu2YPMGqQExwTHBMcExwTHBMcExwTHBMcExwTHBMcExwTHBMcExwTHBMcExwTHBMcExwTHPpYWApvhLo+MAAAAAElFTkSuQmCC);
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    color: #163f70;
    padding: 0 5px;
}

.mark,
mark {
    background-color: #fcf8e32b !important;
}

/* Small */

@media (max-width: 576px) {

    .p1 {
        color: #285287;
        text-align: center;
        font-size: 18px !important;
        font-weight: 600;
    }

}

@media (max-width: 800px) {
    .newsexplore {
        position: inherit !important;
    }
}

/* Medium */

@media (min-width: 768px) {
    body {
        font-size: 16px;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 18px;
    }

    .team-member {
        margin: 3rem 0rem;
    }

    .feature-star {
        padding-top: 0.3rem;
    }
}

/* Large */

@media (min-width: 992px) {
    body {
        font-size: 18px;
    }

    h1 {
        font-size: 50px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 30px;
    }

    h4 {
        font-size: 20px;
    }

    .interface img {
        width: 100%;
        height: 36rem;
        object-fit: cover;
        object-position: top;
    }
}

/* Extra Large */

@media (min-width: 1200px) {
}

.app-section__container {
    min-height: 520px;
}

.app-section__container .newsexplore {
    top: -20px;
}

/* Header */

.header {
    font-size: 14px;
}

.navbar button {
    border: hidden;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 30 30'><path stroke='rgba(22, 63, 112, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.header .logo {
    height: 60px;
    object-fit: cover;
    object-position: top;
}

/* Header links */

.header a:link,
.header a:visited {
    color: var(--primary);
    text-decoration: none;
    line-height: 2.4rem;
    font-weight: 500;
    /* identical to box height, or 150% */
}

.header a:hover,
.header a:active {
    color: var(--secondary);
    text-decoration: none;
}

/* Footer */

.footer {
    color: #fff;
    font-size: 0.8rem;
    background-color: var(--primary);
    font-family: 'Graphik Medium', 'Amiri', serif;
    font-weight: 500;
    line-height: 350%;
}

.footer div {
    text-align: center;
}

.footer p {
    margin: 0;
    text-align: center;
}

.footer p > a:first-child {
    margin-right: 1rem;
}

.footer .logo {
    height: 80px;
}

.footer .footer-link-wrapper {
    display: block;
}

.footer .social-media {
    display: inline-block;
    width: 1.5rem;
    margin: 0 10px;
}

.footer .social-media > .social-media-item {
    width: 100%;
    margin: 0 10px;
}

div.social-links {
    width: 150px;
}

div.social-links a {
    font-size: 2.2rem;
}

.footer .dropdown button {
    border-radius: 12px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--primary);
    width: 120px;
    height: 46px;
}

.dropdown-toggle::after {
    margin-left: 0.6em;
    margin-right: 0;
    vertical-align: 0.255em;
}

.dropdown:lang(ar) .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.6em;
    vertical-align: 0.255em;
}

.footer .dropdown button:hover,
.footer .dropdown button:focus,
.footer .dropdown button:active {
    color: #fff;
    background-color: var(--primary);
    border: 1px solid var(--secondary);
}

.footer-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

/* Footer links */

.footer a:link,
.footer a:visited {
    color: #fff;
    text-decoration: none;
}

.footer a:hover,
.footer a:active {
    color: var(--secondary);
    text-decoration: none;
}

.footer .dropdown-menu a.dropdown-item:link,
.footer .dropdown-menu a.dropdown-item:hover,
.footer .dropdown-menu a.dropdown-item:focus,
.footer .dropdown-menu a.dropdown-item:active {
    color: var(--primary) !important;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

@media (min-width: 768px) {
    .header .logo {
        height: 74px;
        width: auto;
        object-fit: cover;
        object-position: top;
    }

    .footer div {
        text-align: start;
    }

    .footer p {
        text-align: start;
    }

    .footer .logo {
        margin: 0 1rem;
    }
}

/* Large */

@media (min-width: 992px) {
    .header {
        font-size: 15px;
        margin: 0 4rem 0 4rem;
    }

    .header .logo {
        height: 60px;
    }

    .footer .logo {
        height: 88px;
        margin: 0 1rem;
    }

    .footer .footer-link-wrapper {
        display: flex;
        justify-content: space-between;
    }
}

/* Extra Large */

@media (min-width: 1200px) {
}

.filter-page {
    position: relative;
}

.filter-form-wrapper {
    position: relative;
    width: 100%;
    margin-top: -22px;
}

.filter-form {
    position: relative;
}

.filter-items {
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 10px;
}

.filter-item {
    position: relative;
    flex: 1 1;
    width: 100%;
}

.dropdown-container {
    position: relative;
    line-height: 30px;
    border-radius: 10px;
    border: none;
}

.rmsc .dropdown-container {
    border: 1px solid #E5E5E5 !important;
    border-radius: 10px !important;
    padding: 4px 0 !important;
}

.rmsc .dropdown-container:hover,
.rmsc .dropdown-container:focus {
    outline: none !important;
}

.dropdown-container > .label-elem {
    position: relative;
    float: left;
    line-height: 30px;
    margin: 0 5px;
    white-space: nowrap;
}

.dropdown-container > .label-elem > span {
    position: relative;
    float: left;
    font-size: 14px;
    color: #999;
    line-height: 30px;
    white-space: nowrap;
}

.search__container.health-service-search {
    margin-top: 0;
}

.dropdown-container > .input-elem {
    position: relative;
    float: left;
    line-height: 30px;
    width: 100%;
}

.dropdown-container > .input-elem .nav-link {
    padding: 0;
    font-size: 16px;
    color: #333;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.dropdown-container > .input-elem .dropdown-toggle {
    max-width: 120px;
}

.dropdown-container > .input-elem .dropdown-toggle:after {
    display: none;
}

.dropdown-container > .input-elem .dropdown-menu {
    margin-top: 10px !important;
    padding: 10px 0;
}

.dropdown-container > .input-elem .check-elem {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    margin: 0 10px;
}

.dropdown-container > .input-elem .check-elem > div {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    border: 1px solid #E5E5E5;
    background-color: #fff;
    border-radius: 12px;
}

.dropdown-container > .input-elem  .check-elem > div.active-check {
    border: 1px solid #e07777;
    background-color: #ffe1e1;
    border-radius: 12px;
}

.dropdown-container .dropdown-item {
    padding: 8px 5px;
}

.dropdown-container > .input-elem .text-elem {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.fixed-dropdown {
    max-width: 220px;
}

@media (max-width: 768px) {
    .filter {
        justify-content: space-between;
    }
    .filter-wrapper {
        margin-top: 20px;
    }

    .filter-items {
        display: block;
    }

    .filter-item {
        margin-top: .8rem;
    }

    .fixed-dropdown {
        max-width: 100%;
    }

}

.rmsc {
    --rmsc-main: transparent !important; 
}

.rmsc input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
	background-color: #fafafa;
	border: 3px solid #FF9D96;
    width: 22px;
    height: 22px;
    min-width: 22px;
    top: 5px;
	border-radius: 100%;
	display: inline-block;
	position: relative;
}

.rmsc input[type="checkbox"]:checked:before {
    position: absolute;
    content: ' ';
    background-color: #e07777;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    left: 2px;
    top: 2px;
}
.faq-page {
    position: relative;
}

.faq-form-wrapper {
    position: relative;
    width: 100%;
    margin-top: -22px;
}

.faq-form {
    position: relative;
}

.faq-items {
    position: relative;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 10px;
}

.faq-item {
    position: relative;
    flex: 1 1;
}

.faq-list {
    position: relative;
    margin-top: 8rem;
    margin-bottom: 10rem;
}

.provider {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.provider-detail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.provider-detail-title {
    max-width: 20px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: -5px;
}

.provider-image {
    position: relative;
    width: 4rem;
    height: auto;
}

.image-item {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.faq-contact-wrapper {
    position: relative;
    display: flex;
    align-items:flex-start;
    background-color: #E7F5FD;
}

.contact-image {
    position: relative;
}

.image-wrapper {
    position: relative;
    width: 60rem;
    margin-top: -10rem;
    margin-bottom: -5.5rem;
}

.image-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-text-wrapper {
    position: relative;
}

.contact-text {
    position: relative;
    padding: 5rem;
}

.contact-text-description {
    position: relative;
    max-width: 21rem;
    margin-bottom: 2rem;
}

.text-button {
    background-color: #174070;
    padding: 14px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #174070;
    text-decoration: none !important;
}

.text-button:hover {
    background-color: #ffffff;
    color:  #174070;
    cursor: pointer;
}

.faq-not-found {
    color: black;
}

@media (max-width: 768px) {

    .provider {
        display: block;
    }

    .provider-detail {
        margin-bottom: 2rem;
    }

    .provider-detail-title {
        max-width: none;
    }

    .faq-contact-wrapper {
        display: block;
    }
    .contact-text-description {
        max-width: none;
    }

    .image-wrapper {
        width: 100% !important;
        margin-top: -1rem;
        margin-bottom: -2rem;
    }

    .contact-image {
        padding: 0;
    }
    .contact-text {
        padding: 3rem;
    }

}

@media (max-width: 992px) {

    .image-wrapper {
        width: 46rem;
        margin-top: -8rem;
    }
}

.detail-header {
    position: relative;
}

.detail-body {
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.06);
}

.header-detail {
    position: relative;
    margin-top: 3rem;
}

.detail-badge {
    width: 12rem;
    font-size: 1rem !important;
}

.detail-title {
    text-align: center;
}

.detail-info {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #C4C4C4;
    max-width: 30rem;
    padding-bottom: 2rem;
}

.detail-info-item {
    display: inline-block;
    margin-right: 1rem;
    font-size: 20px;
}

.detail-buttons {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 30rem;
}

.detail-button-item{
    background-color: transparent;
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-weight: 550;
    color: #000000;
}

.detail-button-item:hover {
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ff9790 ;
    color: #ff9790;
}

.detail-icon {
    margin-right: 10px;
}

.hospital-review {
    position: relative;
    color: #000000;
}

.address {
    position: relative;
    line-height: 30px;
    font-family: Graphik;
    font-size: 20px;
    margin-bottom: 7rem;
}

.address-item {
    text-align: center;
}

.review-wrapper {
    position: relative;
}

.review {
    position: relative;
}

.text {
   position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.text-title{
    text-align: center;
    color: #174070;
    margin-bottom: 1.5rem;
}

.text-description {
    text-align: center;
    font-size: 22px;
    max-width: 22rem;
}

.stars {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    background-color: #F6F6F6;
    border-radius: 16px;
}

.review-question {
    font-weight: 600;
}

.btn{
    font-weight: 700;
}

.submit-button-hover:hover {
    background-color: #173F70;
    opacity: 50%;
    color: #ffffff;
}



@media (max-width: 576px) {

    .detail-buttons {
        display: block;
    }

    .detail-button-item {
        margin-bottom: 1rem;
    }
}


@media (max-width: 576px) {

    .app-image {
        position: relative;
        text-align: center;
        margin-top: 20px;
    }

    .app-image > img {
        width: 80%;
    }

    .store-image img:first-child {
        margin-right: 0;
    }

}

.store-image {
    position: relative;
}

.store-image img {
    width: 160px;
    object-fit: contain;
    margin-bottom: 16px;
}

.store-image img:first-child {
    margin-right: 20px;
}

