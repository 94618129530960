@import url('./fonts/fonts.css');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&family=Tajawal:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Amiri:700&display=swap');

:root {
    --direction: ltr;
    --align: left;
    --primary: #163f70;
    --secondary: #ff9385;
}

body {
    direction: var(--direction);
    margin: 0;
    font-family: 'Graphik', 'Almarai', sans-serif;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 35px;
}

h2 {
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 20px;
}

h3 {
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 20px;
}

h4 {
    text-align: var(--align);
    font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
    font-size: 16px;
    margin: 8px 0;
}

.turkishFont h1,
.turkishFont h2,
.turkishFont h3,
.turkishFont h4 {
    font-family: 'PT Serif Bold', 'Amiri', serif;
}

div {
    text-align: var(--align);
}

p {
    text-align: var(--align);
}

.titled-text,
.titled-text > * {
    text-align: inherit;
}

.titled-text p {
    color: #000;
}

#header-title {
    color: #285287;
    line-height: 1.6;
}


.titled-text .main-description {
    font-size: 24px;
    line-height: 36px
}

.titled-text .standart-description {
    line-height: 32px;
}

.titled-text .secondary-description {
    font-size: 20px;
    line-height: 30px;
}

.highlight-pink {
    display: inline-block;
    background-image: url('../media/highlight-pink.svg');
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0 5px;
}

.bg-gray {
    background-color: #f7f7f7;
}

.bg-light-blue {
    background: #e7f5fd;
}

.secondary-color {
    color: var(--secondary);
}

.secondary-bg {
    background-color: #fcedee;
}

.depth-shadow {
    box-shadow: 3px 3px 3px rgba(143, 136, 136, 0.2);
    background: #fff;
}

.btn {
    color: #fff;
    background-color: var(--primary);
    border: none;
    border-radius: 12px;
    height: 46px;
    width: 15rem;
    font-size: 14px;
}

.btn:hover,
.btn:focus,
.btn:active {
    color: var(--secondary);
    background-color: var(--primary);
}

.form-control {
    border: 1px solid #e6e6e6 !important;
    box-sizing: border-box;
    border-radius: 12px;
}

.form-control {
    padding: 1.375rem 0.75rem;
}

.categories:hover {
    background: #ffffff;
    box-shadow: 0px 24px 100px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    text-decoration: none !important;
}

.category-link:hover{
    text-decoration: none;
}

.terms-text {
    color: black;
}

.p1 {
    color: #285287;
    text-align: center;
    font-size: 36px !important;
    font-weight: 600;
}

.privacy-text {
    color: black;
}

/* About page */

.md-team-carousel .BrainhubCarouselItem {
    display: block;
}

.md-team-carousel {
    padding-bottom: 5rem;
}

.slider-arrow-right,
.slider-arrow-left {
    border-radius: 39px;
    padding: 1.3rem;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    /* left: 0; */
    z-index: 99;
    margin: 3rem;
}

.slider-arrow-left {
    left: 49%;
}

.slider-arrow-right {
    right: 49%;
    transform: rotate(180deg);
}

.md-team-carousel .slider-arrow-right,
.md-team-carousel .slider-arrow-left {
    background: #2e537e;
}

.team-section {
    color: #fff;
}

.team-card {
    background-color: var(--primary);
    min-height: 30rem;
}

.team-member {
    min-height: 6rem;
}

.team-member > p {
    color: #fff;
    opacity: 0.8;
}

.country-button {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    width: 130px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: -0.416667px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 133%;
}

.country-button-active {
    background: #fff;
    color: #174070;
}

/* AppPage */

.screen-section {
    min-height: 34rem;
}

.screen img {
    width: 50%;
}

.interface img {
    width: 100%;
    height: 26rem;
    object-fit: cover;
    object-position: top;
}

.feature-star {
    padding-top: 0.6rem;
}

/* News Page */

.news-cover {
    background-color: #f7f7f7;
}

.news-cover img {
    display: inline-block;
}

.news-main {
    background: rgba(252, 237, 238, 0.36);
}

.news-card {
    border: none;
}

.news-card a {
    color: var(--secondary);
    text-decoration: none;
}

.newsexplore {
    position: absolute;
    z-index: -1;
}

.news-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000 !important;
}

.news-content:hover {
    text-decoration: none;
}

.text-blue {
    color: #285287;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
}

/* Small */

@media (min-width: 576px) {
}

.highlight {
    display: inline-block;
    background-image: url('../media/highlight-pink.png');
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    color: #163f70;
    padding: 0 5px;
}

.mark,
mark {
    background-color: #fcf8e32b !important;
}

/* Small */

@media (max-width: 576px) {

    .p1 {
        color: #285287;
        text-align: center;
        font-size: 18px !important;
        font-weight: 600;
    }

}

@media (max-width: 800px) {
    .newsexplore {
        position: inherit !important;
    }
}

/* Medium */

@media (min-width: 768px) {
    body {
        font-size: 16px;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 18px;
    }

    .team-member {
        margin: 3rem 0rem;
    }

    .feature-star {
        padding-top: 0.3rem;
    }
}

/* Large */

@media (min-width: 992px) {
    body {
        font-size: 18px;
    }

    h1 {
        font-size: 50px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 30px;
    }

    h4 {
        font-size: 20px;
    }

    .interface img {
        width: 100%;
        height: 36rem;
        object-fit: cover;
        object-position: top;
    }
}

/* Extra Large */

@media (min-width: 1200px) {
}

.app-section__container {
    min-height: 520px;
}

.app-section__container .newsexplore {
    top: -20px;
}
