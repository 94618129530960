.post-header.topic-header {
    height: 350px;
    position: relative;
}

.topic-post {
    margin-top: -140px;
}

.depth-shadow.topic-depth-shadow {
    margin-bottom: 10rem;
    padding-bottom: 8rem;
}

.topic-link-item:hover {
    text-decoration: none;
}

.topic-image {
    width: 10rem;
}

.subtopic-tab-menu {
    background-color: red;
}

.subtopic-body-style {
    margin-top: -80px;
}

.subtopic-header-style {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.06);
}

.icon-design {
    color: #ADADAD;
}

.topic-center {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.topic-category-link {
    color: #000000;
    font-weight: 200;
    font-size: 1.2rem;
}

.topic-badge {
    background: #ff9790;
    border-radius: 32px;
    color: #ffffff;
    padding: .3rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
}

.subtopic-header {
    /*height: 175px;*/
    background-color: #f8f5f5;
}

.subtopic-body {
    background-color: #fff;
    border-radius: 16px;
}

.subtopic-body .titled-text {
    text-align: center;
}

.row.subtopic {
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
}

.subtopic-border {
    border-bottom: 1px solid #E6E6E6;
}

.topic-links {
    background-color: #FFF5F5;
    border-radius: 16px;
}

.topic-link {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    padding: 10px 0;
    font-weight: 600;
    color: #FF9D96;
}

.topic-link:hover {
    cursor: pointer;
}

.active-topic-link {
    background-color: #ff9385;
    color: white;
    text-align: center;
    border-radius: 10px;
}

.subtopic-links {
    color: #000000;
    font-size: 20px;
}

.subtopic-links:hover {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

/* TopicService */

.health-providers {
    border-radius: 16px;
}

.hospitals {
    position: relative;
    padding-right: 6px;
}

.hospitals::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.hospitals::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.hospitals::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.hospitals::-webkit-scrollbar-thumb:hover {
    background: rgb(160, 160, 160);
}

.hospital {
    background-color: white;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.not-found {
    display: flex;
    justify-content: center;
}

.hospital-images {
    position: relative;
    width: 100%;
    max-width: 10rem;
}

.hospital-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.hospital-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000000;
}

.button-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.see-all {
    color: #FF9D96;
    font-size: 16px;
    padding: 5px;
    background-color: #FDE5E3;
    border: 1px solid #FDE5E3;
    border-radius: 8px;
}

.see-all:hover {
    background-color: #FF9D96;
    color: white;
    border: 1px solid #FF9D96;
    text-decoration: none;
}

.phone-button {
    background-color: transparent;
    border: 2px solid #F4F4F4;
    border-radius: 8px;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.phone-icon {
    margin-right: 10px;
}

.sub-info {
    color: #C4C4C4;
    font-size: 15px;
}

.map-google {
    width: 100%
}

.sticky-map {
    position: sticky !important;
    top: 30px;
}

.map-icon {
    position: relative;
}

.google-map-icon-wrapper {
    position: relative;
    width: 1.5rem;
    margin-top: -4.5rem;
}

.google-map-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* X-Small */

@media (max-width: 576px) {
    .subtitles-xs {
        font-family: 'Tiempos Text Medium', 'Amiri', serif;
        color: #163f70;
    }

    .subtitle-border {
        border-bottom: 1px solid #163f70;
    }

    .topic-link {
        line-height: 20px;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .button-wrapper {
        display: block;
    }

    .phone-button {
        margin-bottom: .6rem;
    }
}

/* Medium */

@media (max-width: 768px) {
    .subtopic-body .titled-text {
        text-align: center;
    }

    .hospital {
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .hospital-image {
        margin-top: 1rem;
    }

    .hospital-informations {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .google-map-icon-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }


    .topic-category-link {
        font-size: 1rem;
    }


}

/* Large */

@media (min-width: 992px) {
    .subtopic-header {
        /*height: 414px;*/
    }

    .subtopic-body {}

    .links-border {
        border-right: none;
    }
}

/* Extra Large */

@media (min-width: 1200px) {}