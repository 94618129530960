.filter-page {
    position: relative;
}

.filter-form-wrapper {
    position: relative;
    width: 100%;
    margin-top: -22px;
}

.filter-form {
    position: relative;
}

.filter-items {
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 10px;
}

.filter-item {
    position: relative;
    flex: 1;
    width: 100%;
}

.dropdown-container {
    position: relative;
    line-height: 30px;
    border-radius: 10px;
    border: none;
}

.rmsc .dropdown-container {
    border: 1px solid #E5E5E5 !important;
    border-radius: 10px !important;
    padding: 4px 0 !important;
}

.rmsc .dropdown-container:hover,
.rmsc .dropdown-container:focus {
    outline: none !important;
}

.dropdown-container > .label-elem {
    position: relative;
    float: left;
    line-height: 30px;
    margin: 0 5px;
    white-space: nowrap;
}

.dropdown-container > .label-elem > span {
    position: relative;
    float: left;
    font-size: 14px;
    color: #999;
    line-height: 30px;
    white-space: nowrap;
}

.search__container.health-service-search {
    margin-top: 0;
}

.dropdown-container > .input-elem {
    position: relative;
    float: left;
    line-height: 30px;
    width: 100%;
}

.dropdown-container > .input-elem .nav-link {
    padding: 0;
    font-size: 16px;
    color: #333;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.dropdown-container > .input-elem .dropdown-toggle {
    max-width: 120px;
}

.dropdown-container > .input-elem .dropdown-toggle:after {
    display: none;
}

.dropdown-container > .input-elem .dropdown-menu {
    margin-top: 10px !important;
    padding: 10px 0;
}

.dropdown-container > .input-elem .check-elem {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    margin: 0 10px;
}

.dropdown-container > .input-elem .check-elem > div {
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    border: 1px solid #E5E5E5;
    background-color: #fff;
    border-radius: 12px;
}

.dropdown-container > .input-elem  .check-elem > div.active-check {
    border: 1px solid #e07777;
    background-color: #ffe1e1;
    border-radius: 12px;
}

.dropdown-container .dropdown-item {
    padding: 8px 5px;
}

.dropdown-container > .input-elem .text-elem {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.fixed-dropdown {
    max-width: 220px;
}

@media (max-width: 768px) {
    .filter {
        justify-content: space-between;
    }
    .filter-wrapper {
        margin-top: 20px;
    }

    .filter-items {
        display: block;
    }

    .filter-item {
        margin-top: .8rem;
    }

    .fixed-dropdown {
        max-width: 100%;
    }

}

.rmsc {
    --rmsc-main: transparent !important; 
}

.rmsc input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
	background-color: #fafafa;
	border: 3px solid #FF9D96;
    width: 22px;
    height: 22px;
    min-width: 22px;
    top: 5px;
	border-radius: 100%;
	display: inline-block;
	position: relative;
}

.rmsc input[type="checkbox"]:checked:before {
    position: absolute;
    content: ' ';
    background-color: #e07777;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    left: 2px;
    top: 2px;
}