.card-header {
  background-color: white !important;
}
.card-header {
  border-bottom: 0 !important;
}
.card {
  border: 0 !important;
  border-bottom: 1px solid #bddff4 !important;
}

/* search input form */

#search {
  border-radius: 50px !important;
  border: 3px solid #ff9d96 !important;
  text-align: right;
}
a.categories + a.categories {
  border-left: 3px solid #bddff4 !important;
}
.links {
  text-decoration: none !important;
}
