.detail-header {
    position: relative;
}

.detail-body {
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.06);
}

.header-detail {
    position: relative;
    margin-top: 3rem;
}

.detail-badge {
    width: 12rem;
    font-size: 1rem !important;
}

.detail-title {
    text-align: center;
}

.detail-info {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #C4C4C4;
    max-width: 30rem;
    padding-bottom: 2rem;
}

.detail-info-item {
    display: inline-block;
    margin-right: 1rem;
    font-size: 20px;
}

.detail-buttons {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 30rem;
}

.detail-button-item{
    background-color: transparent;
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-weight: 550;
    color: #000000;
}

.detail-button-item:hover {
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ff9790 ;
    color: #ff9790;
}

.detail-icon {
    margin-right: 10px;
}

.hospital-review {
    position: relative;
    color: #000000;
}

.address {
    position: relative;
    line-height: 30px;
    font-family: Graphik;
    font-size: 20px;
    margin-bottom: 7rem;
}

.address-item {
    text-align: center;
}

.review-wrapper {
    position: relative;
}

.review {
    position: relative;
}

.text {
   position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.text-title{
    text-align: center;
    color: #174070;
    margin-bottom: 1.5rem;
}

.text-description {
    text-align: center;
    font-size: 22px;
    max-width: 22rem;
}

.stars {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    background-color: #F6F6F6;
    border-radius: 16px;
}

.review-question {
    font-weight: 600;
}

.btn{
    font-weight: 700;
}

.submit-button-hover:hover {
    background-color: #173F70;
    opacity: 50%;
    color: #ffffff;
}



@media (max-width: 576px) {

    .detail-buttons {
        display: block;
    }

    .detail-button-item {
        margin-bottom: 1rem;
    }
}

