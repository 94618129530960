.news-carousel .post-cover {
  height: 22.5rem;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 1rem 0 rgba(0, 0, 0, 0.12);
}

.news-carousel h4 {
  margin: 0;
}

.news-carousel a.read-more:link,
.news-carousel a.read-more:visited {
  color: #aea8a8;
}

.news-carousel a.read-more:hover,
.news-carousel a.read-more:focus {
  text-decoration: none;
}

.news-carousel .post-category {
  position: absolute;
  background: #ff9790;
  border-radius: 32px;
  color: #ffffff;
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
}

/********************************/

/** @brainhubeu/react-carousel **/

/********************************/

/* LogoCarousel */

.logo-carousel .slider-arrow-right,
.logo-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

.logo-carousel {
  padding-bottom: 9rem;
}

.logo-carousel .slider-arrow-right,
.logo-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

/* NewsCarousel */

.news-carousel .slider-arrow-right,
.news-carousel .slider-arrow-left {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 39px;
}

.news-carousel {
  padding-bottom: 8rem;
}

.news-carousel .BrainhubCarouselItem {
  cursor: pointer;
  align-items: flex-start;
}

.news-carousel .BrainhubCarouselItem--active h3 {
  display: block;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Newspage carousel */

.news-post .BrainhubCarouselItem > div {
  height: 400px;
  width: 100%;
  margin-bottom: 2rem;
  background-size: cover;
}

.news-post .BrainhubCarousel__customArrows {
  position: relative;
  color: #fff;
  z-index: 1;
}

.news-post .BrainhubCarousel__custom-arrowRight {
  left: -3rem;
}

.news-post .BrainhubCarousel__custom-arrowLeft {
  left: 3rem;
}

/* Quotes carousel */

.quotes-carousel .slider-arrow-right,
.quotes-carousel .slider-arrow-left {
  background: rgba(255, 153, 145, 0.1);
  border-radius: 39px;
}

.quotes-carousel {
  padding-bottom: 6rem;
  text-align: center;
  justify-content: center;
  text-justify: auto;
}

/* .quotes-carousel .slider-arrow-left {
    background: #fce5e5;
    left: 39%;
} */

/* carousel indicators

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  visibility: hidden;
  /* background-color: var(--primary);
  opacity: 0.7;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.5rem;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);


}
.team-section .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    background-color: #fff;
}

.BrainhubCarousel__dots
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
} */

.nayla-logo{
    position: relative;
    max-width: 150px;
}

.nayla-logo-img {
    width: 100%;
    object-fit: contain;
}
