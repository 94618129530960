.post-header {
  height: 250px;
  background: #ffe7e5;
}

.news-post {
  margin-top: -140px;
}

.author {
  opacity: 0.8;
  text-align: center;
}

.commentor {
  opacity: 0.8;
}

.comment-content:after {
  position: absolute;
  content: '';
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 3px rgba(143, 136, 136, 0.2);
  z-index: -1;
}
.newsParegraph {
  color: #000000;
}
.auttor {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  mix-blend-mode: normal;
}
.place {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
}
.social-text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.socialLink {
  display: inline-block;
  text-decoration: none;
  color: #000;
  margin: 0.5em;
  border-radius: 8px;
  transition: 50ms ease-out;
  padding: 0.5em 0.75em;
  line-height: 1.2em;
  background-color: #fff;
}
.socialLink:hover {
  text-decoration: none;
}
.socialIcon {
  fill: #fff;
  stroke-width: 0;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  vertical-align: bottom;
}
.scoialName {
  padding-left: 0.4em;
  line-height: 1.2em;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 56px;
  align-items: center;
  text-align: center;
  color: #000000;
}
.newsParegraph img {
  width: 100%;
}
.new-btn {
  width: 250px !important;
  border-radius: 12px !important;
}
.wp-image-35 {
  width: 100%;
}

.youtube-player {
  width: 100%;
}
.tiled-gallery__gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tiled-gallery__row {
  display: flex;
  justify-content: space-around;
}
.tiled-gallery__col {
  padding: 0 0.5rem;
}
