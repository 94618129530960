/*.contact-header {
  height: 394px;
}

.contact-body {
  margin: -340px;
}

.contact-body .titled-text {
  text-align: center;
}*/

.form-group {
  position: relative;
}

.form-group > label {
  position: absolute;
  top: 0.7rem;
  left: 0.8rem;
  padding: 0 3px;
  background: none;
  color: #939393;
  transition: all 0.1s ease-in-out;
}

.form-group > label.active {
  position: absolute;
  top: -1rem;
  left: 0.8rem;
  padding: 0 3px;
  background: white;
  color: #939393;
  transition: all 0.1s ease-in-out;
}

.form-group > label.filled {
  position: absolute;
  top: -1rem;
  left: 0.8rem;
  padding: 0 3px;
  background: white;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label {
  position: absolute;
  top: 0.7rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: none;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label.active {
  position: absolute;
  top: -1rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: white;
  color: var(--primary);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) .form-group > label.filled {
  position: absolute;
  top: -1rem;
  left: auto;
  right: 0.8rem;
  padding: 0 3px;
  background: white;
  color: rgba(22, 63, 112, 0.7);
  transition: all 0.1s ease-in-out;
}

form:lang(ar) #input-email,
form:lang(ar) #input-phone {
  direction: ltr;
  text-align: right;
}

#contact-form textarea {
  resize: none;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

/*@media (min-width: 768px) {
  .contact-header {
    height: 284px;
  }
  .contact-body {
    margin: -220px;
  }
  .contact-body .titled-text {
    text-align: start;
  }
}*/

/* Large */

/*@media (min-width: 992px) {
  .contact-header {
    height: 414px;
  }
  .contact-body {
    margin: -380px;
  }
}
*/
/* Extra Large */

@media (min-width: 1200px) {
}
.bg-gray-1 {
  background: #f2f2f2 !important;
}
