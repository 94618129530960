.accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.accordion-list__item {
    border-bottom: 1px solid #E6E6E6 !important;
    color: #000000;
    font-size: 20px;
}

.accordion-item__paragraph {
    color: #ADADAD;
    font-size: 16px;
}

.accordion-item--opened .accordion-item__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
}

.accordion-item--opened .accordion-item__content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
}

.accordion-item__line {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
    cursor: pointer;
}

.accordion-item__icon {

}

.accordion-item__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
}

.accordion-item__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
}

.wrapper {
    width: 100%;
}
