.aboutBG {
    background: url('../../media/reachHealthAppBG.svg');
}

@media (max-width: 768px) {

    .home-form-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
