@font-face {
  font-family: 'Graphik Medium';
  src: url('Graphik-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos Headline Bold Test';
  src: url('TiemposHeadlineTest-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Tiempos Text Medium';
  src: url('TiemposText-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
