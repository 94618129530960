.faq-page {
    position: relative;
}

.faq-form-wrapper {
    position: relative;
    width: 100%;
    margin-top: -22px;
}

.faq-form {
    position: relative;
}

.faq-items {
    position: relative;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 10px;
}

.faq-item {
    position: relative;
    flex: 1;
}

.faq-list {
    position: relative;
    margin-top: 8rem;
    margin-bottom: 10rem;
}

.provider {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.provider-detail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.provider-detail-title {
    max-width: 20px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: -5px;
}

.provider-image {
    position: relative;
    width: 4rem;
    height: auto;
}

.image-item {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.faq-contact-wrapper {
    position: relative;
    display: flex;
    align-items:flex-start;
    background-color: #E7F5FD;
}

.contact-image {
    position: relative;
}

.image-wrapper {
    position: relative;
    width: 60rem;
    margin-top: -10rem;
    margin-bottom: -5.5rem;
}

.image-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-text-wrapper {
    position: relative;
}

.contact-text {
    position: relative;
    padding: 5rem;
}

.contact-text-description {
    position: relative;
    max-width: 21rem;
    margin-bottom: 2rem;
}

.text-button {
    background-color: #174070;
    padding: 14px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #174070;
    text-decoration: none !important;
}

.text-button:hover {
    background-color: #ffffff;
    color:  #174070;
    cursor: pointer;
}

.faq-not-found {
    color: black;
}

@media (max-width: 768px) {

    .provider {
        display: block;
    }

    .provider-detail {
        margin-bottom: 2rem;
    }

    .provider-detail-title {
        max-width: none;
    }

    .faq-contact-wrapper {
        display: block;
    }
    .contact-text-description {
        max-width: none;
    }

    .image-wrapper {
        width: 100% !important;
        margin-top: -1rem;
        margin-bottom: -2rem;
    }

    .contact-image {
        padding: 0;
    }
    .contact-text {
        padding: 3rem;
    }

}

@media (max-width: 992px) {

    .image-wrapper {
        width: 46rem;
        margin-top: -8rem;
    }
}
