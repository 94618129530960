/* Homepage */

div.home-cover {
  background-image: linear-gradient(#fbfbfb, #fff);
}

div.home-cover > div {
  text-align: center;
}

div.home-cover img {
  height: 250px;
  display: inline-block;
  /* padding-left: 60px;
    padding-top: 25px; */
}

.home-cover .btn {
  height: 56px;
  width: 167px;
  /* margin-left: 1rem; */
}

.home-cover form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-cover #formBasicEmail {
  background: #ffffff;
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box;
  border-radius: 12px;
  width: 330px;
  height: 56px;
}

.how-section img {
  width: 120%;
}

.num-header {
  color: #c0e2f7;
  font-family: 'Tiempos Headline Bold Test', 'Amiri', serif;
  font-size: 100px;
  line-height: 120%;
}

div.categories + div.categories {
  border-left: 3px solid #c2e4f9;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-right: none;
}

.row:lang(ar) > div.categories + div.categories {
  border-right: 3px solid #c2e4f9;
  border-left: none;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

@media (min-width: 768px) {
  div.home-cover img {
    height: 445px;
  }
  div.home-cover > div {
    text-align: start;
  }
}

/* Large */

@media (min-width: 992px) {
  div.home-cover {
    margin-bottom: 5rem;
  }

  div.home-cover img {
    height: 560px;
  }
  .how-section img {
    width: 100%;
  }
  .num-header {
    font-size: 120px;
  }
}

/* Extra Large */

@media (max-width: 576px) {

    .home-cover #formBasicEmail {
        width: 100%;
        height: 100%;
    }
}

