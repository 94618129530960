.search__container {
    position: relative;
    z-index: 99;
    margin-top: -20px;
}

.form-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.search-button {
    position: absolute;
    background: none;
    border: none;
    font-family: monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.259259px;
    color: #163f70;
}

.search-button:hover {
    color: #ff9d96;
}

.search-box {
    width: 100%;
    padding: 0.5rem;
    padding-left: 40px;
    background-color: white;
    background-image: url('../media/icons/new-search.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    line-height: 30px;
}
