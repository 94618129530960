@media (max-width: 576px) {

    .app-image {
        position: relative;
        text-align: center;
        margin-top: 20px;
    }

    .app-image > img {
        width: 80%;
    }

    .store-image img:first-child {
        margin-right: 0;
    }

}

.store-image {
    position: relative;
}

.store-image img {
    width: 160px;
    object-fit: contain;
    margin-bottom: 16px;
}

.store-image img:first-child {
    margin-right: 20px;
}
