/* Header */

.header {
    font-size: 14px;
}

.navbar button {
    border: hidden;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 30 30'><path stroke='rgba(22, 63, 112, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.header .logo {
    height: 60px;
    object-fit: cover;
    object-position: top;
}

/* Header links */

.header a:link,
.header a:visited {
    color: var(--primary);
    text-decoration: none;
    line-height: 2.4rem;
    font-weight: 500;
    /* identical to box height, or 150% */
}

.header a:hover,
.header a:active {
    color: var(--secondary);
    text-decoration: none;
}

/* Footer */

.footer {
    color: #fff;
    font-size: 0.8rem;
    background-color: var(--primary);
    font-family: 'Graphik Medium', 'Amiri', serif;
    font-weight: 500;
    line-height: 350%;
}

.footer div {
    text-align: center;
}

.footer p {
    margin: 0;
    text-align: center;
}

.footer p > a:first-child {
    margin-right: 1rem;
}

.footer .logo {
    height: 80px;
}

.footer .footer-link-wrapper {
    display: block;
}

.footer .social-media {
    display: inline-block;
    width: 1.5rem;
    margin: 0 10px;
}

.footer .social-media > .social-media-item {
    width: 100%;
    margin: 0 10px;
}

div.social-links {
    width: 150px;
}

div.social-links a {
    font-size: 2.2rem;
}

.footer .dropdown button {
    border-radius: 12px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--primary);
    width: 120px;
    height: 46px;
}

.dropdown-toggle::after {
    margin-left: 0.6em;
    margin-right: 0;
    vertical-align: 0.255em;
}

.dropdown:lang(ar) .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.6em;
    vertical-align: 0.255em;
}

.footer .dropdown button:hover,
.footer .dropdown button:focus,
.footer .dropdown button:active {
    color: #fff;
    background-color: var(--primary);
    border: 1px solid var(--secondary);
}

.footer-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

/* Footer links */

.footer a:link,
.footer a:visited {
    color: #fff;
    text-decoration: none;
}

.footer a:hover,
.footer a:active {
    color: var(--secondary);
    text-decoration: none;
}

.footer .dropdown-menu a.dropdown-item:link,
.footer .dropdown-menu a.dropdown-item:hover,
.footer .dropdown-menu a.dropdown-item:focus,
.footer .dropdown-menu a.dropdown-item:active {
    color: var(--primary) !important;
}

/* Small */

@media (min-width: 576px) {
}

/* Medium */

@media (min-width: 768px) {
    .header .logo {
        height: 74px;
        width: auto;
        object-fit: cover;
        object-position: top;
    }

    .footer div {
        text-align: start;
    }

    .footer p {
        text-align: start;
    }

    .footer .logo {
        margin: 0 1rem;
    }
}

/* Large */

@media (min-width: 992px) {
    .header {
        font-size: 15px;
        margin: 0 4rem 0 4rem;
    }

    .header .logo {
        height: 60px;
    }

    .footer .logo {
        height: 88px;
        margin: 0 1rem;
    }

    .footer .footer-link-wrapper {
        display: flex;
        justify-content: space-between;
    }
}

/* Extra Large */

@media (min-width: 1200px) {
}
